<template>
  <div class="edit-container">
    <div v-if="isAlert" class="alert-body">
      <!--Handle Submit here-->
      <div class="edit-quit" @click="$emit('exitEdit',false)"></div>
      <div><b>Congratulations!</b></div>

      <div class="alert-last-line">
        <div>
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><ellipse style="fill:#32BEA6;" cx="256" cy="256" rx="256" ry="255.832"/><polygon style="fill:#FFFFFF;" points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504 391.88,166.392 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
          <p>Successfully posted your wish! Alumni working at {{ companyName }} will see your post.</p>
        </div>
        <p>Your post will only be <u>valid for 90 days</u>. Your post will be <u>auto-deleted after 120 days of inactivity</u>.
          Please reactivate it after 90 days if you still want it kept.</p>
      </div>
      <!--Handle Submit here-->
      <div class="alert-button" @click="$emit('exitEdit',false)">Finish</div>
    </div>

    <div v-if="!isAlert" class="edit-body">
      <div class="edit-quit" @click="$emit('exitEdit',true)"></div>
      <div class="edit-heading">
        <h5>{{ companyName }}</h5>
        <div class="edit-heading-underline"></div>
      </div>
      <div class="edit-inputs">
        <div class="edit-position">
          <h4>Position *</h4>
          <input v-if="isNew || isEdit" v-model="position" type="text"/>
          <input v-if="IWantThisToo" :value="wish.position" class="disable-input" disabled="disabled" type="text"/>
        </div>
        <div class="edit-location">
          <h4>Location *</h4>
          <div>
            <input v-if="isNew || isEdit" v-model="city" placeholder="City" type="text"/>
            <input v-if="IWantThisToo" :value="wish.city" class="disable-input" disabled="disabled" type="text"/>
            <input v-if="isNew || isEdit" v-model="state" placeholder="State" type="text"/>
            <input v-if="IWantThisToo" :value="wish.state" class="disable-input" disabled="disabled" type="text"/>
          </div>
        </div>
        <div class="edit-link">
          <h4>Job Description Link *</h4>
          <input v-if="isNew || isEdit" v-model="link" type="text"/>
          <input v-if="IWantThisToo" :value="wish.link" class="disable-input" disabled="disabled" type="text"/>
        </div>
        <div class="edit-resume">
          <h4>Resume *</h4>
          <div class="edit-resume-top">
            <input v-model="isDefaultResume" type="checkbox"/>
            <div class="default-resume">Use default resume.</div>
          </div>
        </div>
        <div v-if="isEdit" class="edit-resume-link">
          Current Resume Link:
          <input :id="wish.resumeUrl" :value="resumeUrl" class="resume-link" disabled="disabled" type="text"/>
        </div>
        <div v-if="isEdit" class="edit-resume-link">
          Current Resume Name:
          <input :id="wish.resumeUrl" :value="initialResumeTag" class="resume-link" disabled="disabled" type="text"/>
        </div>

        <div v-if="!isDefaultResume" class="upload-resume">
          <div class="upload-filler"></div>
          <div class="credentials-change-block">
            <input
                id="input-resume"
                ref="resume"
                class="input-file"
                type="file"
                @change="changeTag"
            />
            <label
                class="input-file-label"
                for="input-resume"
            >
              <ion-icon class="label-icon" name="cloud-upload-outline"></ion-icon>
              <p v-if="resumeTag">
                {{ resumeTag }}
              </p>
              <p v-else>Choose A New Resume...</p>
            </label>
          </div>
        </div>
      </div>
        <div class="edit-submit" v-if="isNew" @click="handleSubmit">Post</div>
        <div class="edit-submit" v-if="isEdit" @click="handleEdit">Save Edits</div>
        <div class="edit-submit" v-if="IWantThisToo" @click="handleSubmit">Sign Me Up</div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import getUser from "@/composables/getUser";
import getProfile from "@/composables/Profile/getProfile";
import createNewWish from "@/composables/WishList/createNewWish";
import addCandidateToWish from "@/composables/WishList/addCandidateToWish";
import editWish from "@/composables/WishList/editWish";
import addMyWish from "@/composables/Profile/addMyWish";
import cutMyWish from "@/composables/Profile/cutMyWish";
import uploadWishResume from "@/composables/Storage/uploadWishResume";
import deleteWishResume from "@/composables/Storage/deleteWishResume";
import getWishResume from "@/composables/Storage/getWishResume";
import getUrlAndName from "@/composables/Storage/getUrlAndName";
// importing notification lib
// https://vuejsexamples.com/a-lightweight-toast-or-notification-or-snack-bar-for-vue3/
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
  props: ["wish", "companyName", "companyId", "IWantThisToo", "isDefaultResume", "isNew", "isEdit"],
  async setup(props, {emit}) {
    const {user} = getUser();
    const profile = ref(null);
    await getProfile(profile, user.value.uid);

    const position = ref(null);
    const city = ref(null);
    const state = ref(null);
    const link = ref(null);

    // resume variables
    const isDefaultResume = ref(true);
    const isUploadingResume = ref(false);
    const resume = ref(null);
    const resumeTag = ref(null);
    const initialResumeTag = ref(null);
    const resumeUrl = ref(null);
    const defaultResumeUrl = ref(null);
    const defaultResumeTag = ref(null);
    await getUrlAndName(defaultResumeUrl, defaultResumeTag, user.value.uid, "Resumes");

    const newWishId = ref(null);
    const candidateNameInitials = ref(null);
    candidateNameInitials.value = profile.value.firstName.slice(0, 1).toUpperCase() + ". " + profile.value.lastName.slice(0, 1).toUpperCase() + ". ";

    // alert before emit close wishEdit
    const isAlert = ref(false);

    // get initial values for wish properties
    if (props.isEdit) {
      position.value = props.wish.position;
      city.value = props.wish.city;
      state.value = props.wish.state;
      link.value = props.wish.link;

      isDefaultResume.value = props.isDefaultResume;

      if (props.isDefaultResume){
        resumeUrl.value = defaultResumeUrl.value;
        resumeTag.value = null;
        if (defaultResumeTag.value){
          initialResumeTag.value = defaultResumeTag.value;
        } else {
          initialResumeTag.value = "Your Current Default Resume";
        }
        
      } else {
        await getWishResume(resumeUrl, initialResumeTag, user.value.uid, props.wish.wishId);
        resumeTag.value = null;
      }
    }

    // create notifications
    const contentNotChangedAlert = () => {
      createToast({
        title: "Edit saved!",
        description: 'Please be noted you have not make any change.'
      })
    }
    const resumeUploadedAlert = () => {
      createToast({
        title: "Success!",
        description: 'Resume for wish uploaded to the database.'
      }, {
        type: "success"
      })
    }
    const noResumeAlert = () => {
      createToast({
        title: "No resume found!",
        description: 'Please upload a resume here or upload default resume at Account Settings.'
      }, {
        type: "warning"
      })
    }
    const uploadResumeErrorAlert = () => {
      createToast({
        title: "Unexpected error",
        description: 'Some unknown error happened. Please upload again.'
      }, {
        type: "danger"
      })
    }
    const uploadingAlert = () => {
      createToast({
        title: "Uploading!",
        description: 'Please wait a few seconds for letting the data be processed.'
      }, {
        type: "success"
      })
    }

    const handleUpload = async () => {
      try {
        if (resume.value.files[0]) {
          //.log("wishId: "+ props.wish.wishId);
          await deleteWishResume(user.value.uid, props.wish.wishId);
          await uploadWishResume(resume.value.files[0], user.value.uid, props.wish.wishId, resumeUrl);
          isUploadingResume.value = false;
          resume.value = null;
          resumeTag.value = null;
          resumeUploadedAlert();
        } else {
          noResumeAlert();
        }
      } catch (err) {
        uploadResumeErrorAlert();
      }
    };

    const handleSubmit = async () => {
      //console.log("-----------------")
      uploadingAlert()
      try {
        if (props.IWantThisToo) {
          if (isDefaultResume.value) {
            // console.log(props.companyId, props.wish.wishId, props.wish.candidateCount);
            if (!defaultResumeUrl.value) {
              alert("Please Note: You do NOT have a default resume for now. You can upload your default resume in Account Settings.");
            } else {
              await addCandidateToWish(props.companyId, props.wish.wishId, props.wish.candidateCount, user.value.uid, candidateNameInitials.value, true);
              await addMyWish(user.value.uid, props.companyId, props.wish.wishId, "pending");
              emit('exitEdit', false);
            }
          } else {
            if (resume.value.files[0]) {
              await handleUpload();
              await addCandidateToWish(props.companyId, props.wish.wishId, props.wish.candidateCount, user.value.uid, candidateNameInitials.value, false);
              await addMyWish(user.value.uid, props.companyId, props.wish.wishId, "pending");
              emit('exitEdit', false);
            } else {
              alert("You must use your Default Resume or choose to Upload a Different Resume. Aborting Wish Submission.");
            }
          }
        } else if (props.isNew) {
          if (isDefaultResume.value) {
            // console.log(props.companyName, props.companyId, props.isNew);
            if (!defaultResumeUrl.value) {
              alert("Please Note: You do NOT have a default resume for now. You can upload your default resume in Account Settings.");
            } else {
              await createNewWish(props.companyId, props.companyName, user.value.uid, {
                position: position.value,
                link: link.value,
                city: city.value,
                state: state.value,
                candidateInitials: candidateNameInitials.value
              }, newWishId, true);
              isAlert.value = true;
              await addMyWish(user.value.uid, props.companyId, newWishId.value, "pending");
            }
          } else {
            if (resume.value.files[0]) {
              await handleUpload();
              await createNewWish(props.companyId, props.companyName, user.value.uid, {
                position: position.value,
                link: link.value,
                city: city.value,
                state: state.value,
                candidateInitials: candidateNameInitials.value
              }, newWishId, false);
              await addMyWish(user.value.uid, props.companyId, newWishId.value, "pending");
            } else {
              alert("You must use your Default Resume or choose to Upload a Different Resume. Aborting Wish Submission.");
            }
          }
        }
      } catch (err) {
        alert(err);
      }
    }

    const handleEdit = async () => {
      if (position.value === props.wish.position &&
          state.value === props.wish.state &&
          city.value === props.wish.city &&
          link.value === props.wish.link &&
          initialResumeTag.value === resumeTag.value &&
          !(props.isDefaultResume === false && isDefaultResume.value === true)) {
        contentNotChangedAlert()
        setTimeout(() => {
          emit('exitEdit', false);
        }, 2000)
      } else {
        let isUpdateResumeOnly = false;
        if (position.value === props.wish.position &&
            state.value === props.wish.state &&
            city.value === props.wish.city &&
            link.value === props.wish.link){
          isUpdateResumeOnly = true;
        }
        if (isDefaultResume.value) {
          // console.log("default in place");
          if (!defaultResumeUrl.value) {
            noResumeAlert()
          } else {
            await editWish(isUpdateResumeOnly, props.companyId, props.companyName, props.wish.wishId, user.value.uid, props.wish.candidateCount, {
              position: position.value,
              link: link.value,
              city: city.value,
              state: state.value,
              candidateInitials: candidateNameInitials.value
            }, newWishId, true)
          }
        } else {
          // console.log("non-default");
          if (resume.value.files[0]) {
            await handleUpload();
            await editWish(isUpdateResumeOnly, props.companyId, props.companyName, props.wish.wishId, user.value.uid, props.wish.candidateCount, {
              position: position.value,
              link: link.value,
              city: city.value,
              state: state.value,
              candidateInitials: candidateNameInitials.value
            }, newWishId, false);
          } else {
            noResumeAlert()
            return;
          }
        }
        if (!isUpdateResumeOnly){
          await cutMyWish(user.value.uid, props.wish.wishId);
          await addMyWish(user.value.uid, props.companyId, newWishId.value, "pending");
        }
        emit('exitEdit', false);
      }
    }

    const changeTag = () => {
      if (resume.value) {
        if (resume.value.files[0]) {
          resumeTag.value = resume.value.files[0].name;
        }
      }
    };

    return {
      position,
      city,
      state,
      link,
      isDefaultResume,
      isUploadingResume,
      isAlert,
      resume,
      resumeUrl,
      resumeTag,
      initialResumeTag,
      handleUpload,
      handleSubmit,
      handleEdit,
      changeTag,
      contentNotChangedAlert,
      resumeUploadedAlert,
      noResumeAlert,
      uploadResumeErrorAlert,
      uploadingAlert
    }
  }
}
</script>

<style scoped>
.edit-container {
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: rgba(10, 7, 24, 0.7);
}

.edit-body {
  position: absolute;
  top: 10vh;
  margin-left: 20vw;
  background-color: #F4F4F4;
  min-height: 600px;
  width: 60vw;
  border-radius: 20px;
  box-shadow: 1px 4px 11px 3px rgba(0, 0, 0, 0.25);
  animation: fadein 0.5s 1;
}

/*close button*/
.edit-quit {
  position: absolute;
  top: 2vh;
  right: 2vw;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
}

.edit-quit::before,
.edit-quit::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
  height: 3px;
  margin-top: -4px;
}

.edit-quit::before {
  transform: rotate(45deg);
}

.edit-quit::after {
  transform: rotate(-45deg);
}

.edit-quit:hover {
  cursor: pointer;
}

.edit-heading {
  position: absolute;
  top: 5vh;
  left: 5vw;
  width: 80%;
}

.edit-heading h5 {
  padding-bottom: 1vh;
  font-size: larger;
  font-weight: bold;
  color: black;
}

.edit-heading-underline {
  width: 100%;
  border: 1px solid #646464;;
}

.edit-inputs {
  width: 58vw;
  position: absolute;
  top: 10vh;
  padding-top: 2vh;
}

.edit-position,
.edit-location,
.edit-link,
.edit-resume,
.upload-resume,
.edit-resume-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4vh;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  color: black;
}

.edit-resume,
.edit-resume-link {
  padding-bottom: 1vh;
}

.upload-resume {
  position: relative;
}

input {
  border: 1px solid #646464;
  border-radius: 5px;
  height: 3vh;
}

input[type="file"] {
  display: none;
}

input:focus {
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-color: var(--purple);
}

.edit-position input,
.edit-location div,
.edit-link input,
.edit-resume div,
.upload-resume div,
.resume-link {
  width: 60%;
}

.resume-link {
  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 7px;
}

.upload-resume div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-filler {
  flex: 0 1 40%;
}

.upload-btn {
  display: block;
  border: 2px #cca2d8 solid;
  border-radius: 2vh;
  background-color: white;
  color: #cca2d8;
  text-align: center;
  height: 4vh;
  width: 180px;
}

.upload-btn:hover {
  border-color: #b948d8;
  color: #b948d8;
  cursor: pointer;
}

.credentials-change-block {
  flex: 0 1 60%;
  display: inline-block;
  padding: 1vh 3vh;
  border: 2px #cca2d8 dashed;
  border-radius: 2vh;
}

.credentials-change-block h4 {
  font-size: 75%;
  font-weight: 400;
  margin: 0.5vh 0;
}

.credentials-change-block h4 input {
  width: 20vw;
  border: none;
  border-bottom: 1px rgba(128, 128, 128, 0.5) solid;
  padding: 1vh 1vw;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file-label {
  margin: 1vh 1vh 0 0;
  padding: 0.5vh 0.5vw;
  font-size: 75%;
  text-align: center;
  color: var(--purple-l);
  border-bottom: 1px transparent solid;
}

.input-file-label p {
  display: inline;
}

.input-file-label:hover {
  cursor: pointer;
  border-color: var(--purple-l);
}

.label-icon {
  font-size: 150%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  fill: currentColor;
  margin-top: 0;
  margin-right: 8px;
}

.confirmChangeBtn {
  margin: 0.5vh 0;
  height: 36px;
  width: 140px;
  color: white;
  background-color: #cca2d8;
  border: 2px #cca2d8 solid;
  border-radius: 2vh;
}

.confirmChangeBtn:hover {
  border-color: #bf66d8;
  background-color: #bf66d8;
  cursor: pointer;
}

.upload-nofile {
  color: #646464;
  margin-left: 10px;
}

.upload-container {
  background: #C4C4C4;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.edit-resume-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edit-resume-top input {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.edit-resume-top div {
  white-space: nowrap;
}

.edit-location div {
  display: flex;
  justify-content: space-between;
}

.edit-location div input {
  width: 48%;
}

.edit-submit {
  color: white;
  position: absolute;
  bottom: 5vh;
  right: 7vw;
  background: var(--purple-l);
  width: 125px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.edit-submit:active {
  background-color: var(--purple-s);
  transform: translateY(1px);
}

.edit-submit:hover {
  cursor: pointer;
}

@keyframes pop-alert {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1.0);
  }
}

.disable-input {
  background-color: #EAEFFA;
}

.alert-body {
  position: relative;
  width: 30vw;
  margin: 25vh auto 0 auto;
  background-color: white;
  padding: 7vh 4vw 4vh 4vw;
  border-radius: 17px;
  animation: fadein ease-in 0.2s;
}

.edit-quit {
  position: absolute;
  top: 2vh;
  right: 1vw;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  transition: ease-in-out 0.3s;
}

.edit-quit::before,
.edit-quit::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
  height: 3px;
  margin-top: -4px;
}

.edit-quit::before {
  transform: rotate(45deg);
}

.edit-quit::after {
  transform: rotate(-45deg);
}

.edit-quit:hover {
  cursor: pointer;
}

.edit-quit:active {
  opacity: 0.5;
}

.alert-body div {
  margin: 30px 0 30px 0;
  font-size: var(--subheader);
  color: black;
}

.alert-button {
  width: 200px;
  padding: 15px 25px 15px 25px;
  text-align: center;
  color: white !important;
  font-weight: bold;
  font-size: var(--text);
  background-color: var(--purple-l);
  border-radius: 7px;
  margin-left: auto !important;
  transition: 0.3s ease-out;
}

.alert-button:hover {
  cursor: pointer;
}

.alert-button:active {
  background-color: var(--purple-s);
  transform: translateY(1px);
}

.alert-last-line svg {
  width: 5vh;
  float: left;
  margin-right: 15px;
  position: relative;
  top: 5px;
}

@media screen and (max-width: 1700px) {
  .edit-body {
    min-height: 65vh;
  }

  .alert-body {
    width: 60vw;
  }

  .alert-buttons div {
    width: 15vw;
  }

  .edit-quit {
    right: 5vw
  }

  .credentials-change-block {
    padding: 0 3vh;
  }
}

@media screen and (max-width: 800px) {
  .edit-body {
    top: 5vh;
    margin-left: 5vw;
    width: 90vw;
    min-height: 80vh;
  }

  .edit-inputs {
    width: 83vw;
  }

  .edit-position,
  .edit-location,
  .edit-link,
  .edit-resume,
  .edit-resume-link {
    flex-wrap: wrap;
  }

  .edit-resume-top input {
    margin-right: 5px;
  }

  .edit-position input,
  .edit-location div,
  .edit-link input,
  .resume-link {
    margin-top: 2vh;
    width: 100%;
  }

  .edit-resume div {
    margin-top: 2vh;
    width: 90%;
    text-align: right;
  }

  .default-resume {
    margin-top: 0 !important;
  }

  .upload-btn {
    margin: 0;
  }
}


@media screen and (max-width: 600px) {
  .edit-body {
    top: 2vh;
    margin-left: 5vw;
    width: 90vw;
    min-height: 700px;
  }

  .alert-body {
    width: 90vw;
    margin: 15vh auto 0 auto;
  }


  .alert-buttons div {
    width: 35vw;
  }

  .credentials-change-block {
    padding: 0;
    min-width: 200px;
  }
}
</style>
